import React from "react";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <div className="bg-[#071C40] w-full my-12 px-4">
      <div className="max-w-[80rem] mx-auto flex flex-col md:flex-row items-center justify-between py-12 gap-6">
        <div className="text-center md:text-left">
          <h1 className="mb-2 text-2xl md:text-4xl font-semibold text-gray-200 tracking-tight">
            We are here for your assistance!
          </h1>
          <p className="text-sm md:text-base text-gray-200">
            100% ZATCA Compliance: A Saudi-based solution tailored for Saudi businesses.
          </p>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-center gap-4 w-full md:w-1/2">
          <Link
            to="/contact"
            className="w-full md:w-1/2 h-32 bg-blue-50 px-6 border border-slate-200 text-center space-y-3 flex flex-col items-center justify-center rounded"
          >
            <i className="fa-duotone fa-light fa-user-headset text-3xl md:text-4xl text-[#071C40]"></i>
            <p className="text-gray-700 font-medium text-sm md:text-base">Submit a Report</p>
          </Link>

          <Link
            to="/contact"
            className="w-full md:w-1/2 h-32 bg-blue-50 px-6 border border-slate-200 text-center space-y-3 flex flex-col items-center justify-center rounded"
          >
            <i className="fa-duotone fa-light fa-messages-question text-3xl md:text-4xl text-[#071C40]"></i>
            <p className="text-gray-700 font-medium text-sm md:text-base">FAQs</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CTA;
