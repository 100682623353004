import React, { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Helmet } from 'react-helmet';

const loadRecaptchaScript = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=6LcMU1MqAAAAABzpMgT97VYjrgGGgUINweVTELOf`;
  script.async = true;
  document.body.appendChild(script);
};

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [captchaToken, setCaptchaToken] = useState("");
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    loadRecaptchaScript();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    if (!formData.firstName || !formData.lastName || !formData.email || !formData.message) {
      toast.error("Please fill in all the required fields.");
      setIsSending(false);
      return;
    }

    const data = {
      fullName: `${formData.firstName} ${formData.lastName}`,
      from: formData.email,
      message: formData.message,
      appGenre: 'ZATCA.co',
    };

    try {
      const response = await fetch("http://192.168.18.243:9211/api/emails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "BZSoftech@awaisCEO",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to send data");
      }

      toast.success("Successfully submitted - Check your email!", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      alert("Successfully submitted");

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LcMU1MqAAAAABzpMgT97VYjrgGGgUINweVTELOf", { action: "submit" })
          .then((token) => {
            setCaptchaToken(token);
            setFormData({ firstName: "", lastName: "", email: "", message: "" });
          })
          .catch(() => {
            toast.error("reCAPTCHA verification failed. Please try again.");
          });
      });
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred. Please try again.");
    } finally {
      setIsSending(false);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Zatca E-Invoicing for Saudi Arabia</title>
      </Helmet>
      <div className="text-center pt-24 pb-12 bg-[#071C40]">
        <h1 className="text-4xl text-gray-200 leading-tight">
          Get in <span className="text-blue-600 px-2">Touch</span> with us
        </h1>
      </div>
      <section className="text-gray-600 body-font relative">
        <div className="absolute inset-0 bg-gray-300">
          <iframe
            width="100%"
            height="100%"
            title="map"
            src={`https://maps.google.com/maps?q=31.4620802,73.1556677&z=18&t=k&output=embed`}
            className="absolute inset-0"
          ></iframe>
        </div>
        <div className="container md:px-24 px-12 py-24 mx-auto flex">
          <div className="lg:w-1/3 md:w-1/2 bg-white ml-auto rounded-lg p-8 shadow-md relative z-10">
            <h2 className="text-gray-900 text-lg mb-1 font-medium">Contact Us</h2>
            <div className="relative mb-4">
              <label htmlFor="firstName" className="leading-7 text-sm text-gray-600">First Name</label>
              <input type="text" id="firstName" value={formData.firstName} onChange={handleInputChange} className="w-full bg-white rounded border border-gray-300 py-1 px-3" />
            </div>
            <div className="relative mb-4">
              <label htmlFor="lastName" className="leading-7 text-sm text-gray-600">Last Name</label>
              <input type="text" id="lastName" value={formData.lastName} onChange={handleInputChange} className="w-full bg-white rounded border border-gray-300 py-1 px-3" />
            </div>
            <div className="relative mb-4">
              <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
              <input type="email" id="email" value={formData.email} onChange={handleInputChange} className="w-full bg-white rounded border border-gray-300 py-1 px-3" />
            </div>
            <div className="relative mb-4">
              <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
              <textarea id="message" value={formData.message} onChange={handleInputChange} className="w-full bg-white rounded border border-gray-300 py-1 px-3 h-32 resize-none"></textarea>
            </div>
            <button onClick={handleSubmit} className="text-white bg-blue-500 py-2 px-6 rounded">Send Inquiry</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
