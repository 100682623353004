import React, { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';

const OrderStatusPage = () => {
    const { user } = useUser();
    const email = user?.primaryEmailAddress?.emailAddress;
    const [orderStats, setOrderStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!email) return;

        const fetchOrderStats = async () => {
            try {
                const response = await fetch(`https://zatca.bzsconnect.com/api/get-user-stats?email=${email}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setOrderStats(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrderStats();
    }, [email]);

    const BasicVerificationCard = () => (
        <div className="rounded-lg border border-yellow-200 bg-yellow-50 p-6">
            <div className="mb-4">
                <div className="flex items-center gap-2 mb-2">
                    <i className="fas fa-clock text-yellow-500 text-xl"></i>
                    <h2 className="text-xl font-bold text-yellow-700">Verification Pending</h2>
                </div>
                <div className="bg-yellow-100 border border-yellow-200 rounded-lg p-4 mb-4">
                    <div className="flex items-center gap-2 text-yellow-700">
                        <i className="fas fa-exclamation-circle"></i>
                        <p>Your payment is being verified.</p>
                    </div>
                </div>
            </div>

            <div className="bg-white rounded-lg p-6 shadow-sm">
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Full Name</span>
                        <span className="font-semibold">{orderStats?.fullName}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Email</span>
                        <span className="font-semibold">{orderStats?.email}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Status</span>
                        <span className="font-semibold uppercase text-yellow-600">{orderStats?.status}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Purchased On</span>
                        <span className="font-semibold">{new Date(orderStats?.purchasedOn).toLocaleDateString()}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Expiry Date</span>
                        <span className="font-semibold">{new Date(orderStats?.purchasedExpiry).toLocaleDateString()}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    const PremiumVerifiedCard = () => (
        <div className="rounded-lg border border-green-200 bg-green-50 p-6">
            <div className="mb-4">
                <div className="flex items-center gap-2 mb-2">
                    <i className="fas fa-check-circle text-green-500 text-xl"></i>
                    <h2 className="text-xl font-bold text-green-700">Payment Verified</h2>
                </div>
                <div className="bg-green-100 border border-green-200 rounded-lg p-4 mb-4">
                    <div className="flex items-center gap-2 text-green-700">
                        <i className="fas fa-check-circle"></i>
                        <p>Your premium subscription is active and verified.</p>
                    </div>
                </div>
            </div>

            <div className="bg-white rounded-lg p-6 shadow-sm">
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Full Name</span>
                        <span className="font-semibold">{orderStats?.fullName}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Email</span>
                        <span className="font-semibold">{orderStats?.email}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Package</span>
                        <span className="font-semibold uppercase text-green-600">{orderStats?.package}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Status</span>
                        <div className="flex items-center gap-1 font-semibold text-green-600">
                            <i className="fas fa-check-circle"></i>
                            <span>Verified</span>
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Purchased On</span>
                        <span className="font-semibold">{new Date(orderStats?.purchasedOn).toLocaleDateString()}</span>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-600">Expiry Date</span>
                        <span className="font-semibold">{new Date(orderStats?.purchasedExpiry).toLocaleDateString()}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="container mx-auto px-4 py-8 max-w-4xl">
            <div className="mb-8">
                <h1 className="text-3xl font-semibold text-gray-800">Payment Verification Status</h1>
                <p className="text-gray-500">Manage your subscription and billing details</p>
            </div>

            {loading ? (
                <div className="flex items-center justify-center py-12">
                    <i className="fas fa-spinner fa-spin text-gray-600 text-2xl"></i>
                </div>
            ) : error ? (
                <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                    <div className="flex items-center gap-2 text-red-700">
                        <i className="fas fa-exclamation-circle"></i>
                        <p>Error: {error}</p>
                    </div>
                </div>
            ) : (
                orderStats?.status === 'basic' ? <BasicVerificationCard /> : <PremiumVerifiedCard />
            )}
        </div>
    );
};

export default OrderStatusPage;