import React from "react";
import hero from "../../assets/hero.webp";
import zatca from "../../assets/zatca.png";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <section className="relative">
      <section className="bg-[#071C40] overflow-hidden">
        <div className="max-w-7xl mx-auto min-h-screen flex items-center px-6 sm:px-10 md:px-16 lg:px-20">
          <div className="flex flex-wrap items-center justify-center w-full mx-4 my-12">
            {/* Image Section */}
            <div className="w-full md:w-1/2 px-4 mt-8 flex items-center justify-center">
              <div className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mx-auto md:mx-0 rounded-3xl">
                <img className="w-full" src={hero} alt="ZATCA E-Invoicing" />
              </div>
            </div>
            {/* Content Section */}
            <div className="w-full md:w-1/2 p-4 text-center md:text-left">
              <div className="md:max-w-lg mx-auto md:mx-0">
                <span className="inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest secFont">
                  Developed by BZ Softech
                </span>
                <h1 className="mb-3 text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-gray-200 font-semibold tracking-tight">
                  Explore leading <span className="text-blue-600">ERPs</span> &
                </h1>
                <h1 className="mb-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-gray-200 font-semibold tracking-tight">
                  <span className="text-blue-600">E-Invoicing</span> Services
                </h1>
                <p className="mb-6 text-sm sm:text-base text-gray-200">
                  Easily integrate your existing Enterprise Resource Planning (ERP) systems, Point of Sale (POS) solutions, and applications with ZATCA.co in Saudi Arabia. Sign up today to try the ZATCA Services with a free trial!
                </p>
                <div className="flex flex-wrap justify-center md:justify-start gap-4 mb-8">
                  <Link
                    to="/contact"
                    className="text-gray-900 bg-white border px-4 py-2 text-sm font-semibold rounded"
                  >
                    Get a Free Consultation
                  </Link>
                  <Link
                    to="/dashboard"
                    className="text-white bg-gray-900 px-4 py-2 text-sm font-semibold rounded"
                  >
                    Try the ZATCA Services
                  </Link>
                </div>
                <div className="flex flex-col sm:flex-row items-center sm:space-x-4 text-center sm:text-left">
                  <img src={zatca} alt="ZATCA Approved Provider" className="w-16 sm:w-20" />
                  <div>
                    <h1 className="mb-1 text-gray-200 text-lg font-semibold tracking-tight">
                      A ZATCA Approved Service Provider
                    </h1>
                    <p className="text-sm text-gray-200">
                      100% ZATCA Compliance: A Saudi-based service tailored for Saudi businesses.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default HeroSection;