import React, { useState } from "react";
import Sim from "../../assets/sim1.png";
import Sim2 from "../../assets/sim2.png";
import stock from "../../assets/stock.avif";

const ServicesTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="bg-blue-50">
      <section className="max-w-[80rem] px-6 md:px-8 mx-auto py-12">
        <div className="flex flex-wrap -mx-4 mb-8 items-center">
          <div className="w-full lg:w-2/3 xl:w-1/2 px-4 mb-6 lg:mb-0">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900">
              ZATCA e-Services
            </h1>
          </div>
          <div className="w-full lg:w-1/3 xl:w-1/2 px-4">
            <p className="text-lg text-gray-500">
              Explore our comprehensive range of e-invoice and products tailored
              for Saudi businesses to meet your needs and drive success.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8">
          <nav className="w-full md:w-1/3 flex flex-col space-y-4">
            {[
              { id: "tab1", label: "On Site Simulation" },
              { id: "tab2", label: "Embedded Solution" },
              { id: "tab3", label: "On Premises Solution" },
            ].map((tab) => (
              <button
                key={tab.id}
                className={`py-4 px-6 font-semibold rounded-md transition duration-500 w-full text-left ${
                  activeTab === tab.id
                    ? "bg-gray-900 text-white"
                    : "bg-white text-blue-900 hover:bg-gray-900 hover:text-white"
                }`}
                onClick={() => handleTabClick(tab.id)}
                aria-selected={activeTab === tab.id}
              >
                {tab.label}
              </button>
            ))}
          </nav>

          <div className="w-full md:w-2/3">
            {[
              {
                id: "tab1",
                title: "On Site Simulation",
                description:
                  "The ZATCA Services provide easy-to-use and implement On-Site Simulation for each ZATCA e-invoicing and Fatoora solution, featuring Hashing, Signing, QR Code generation, and more.",
                image: Sim,
              },
              {
                id: "tab2",
                title: "Embedded Solution",
                description:
                  "The ZATCA Services provide an easy-to-integrate Embedded Solution for each ZATCA e-invoicing and Fatoora solution through secured and authorized APIs.",
                image: Sim2,
              },
              {
                id: "tab3",
                title: "On Premises Solution",
                description:
                  "The ZATCA Services offer a fully secured and pre-built On-Premises Solution for each ZATCA e-invoicing and Fatoora solution with Oracle APEX integration.",
                image: stock,
              },
            ].map((tab) => (
              <div
                key={tab.id}
                className={`tab-content ${
                  activeTab === tab.id ? "block" : "hidden"
                } bg-white p-6 rounded-md border border-gray-200 flex flex-col space-y-4"}`}
              >
                <h1 className="text-2xl font-light">{tab.title}</h1>
                <p className="text-gray-700">{tab.description}</p>
                <img src={tab.image} alt={tab.title} className="w-full border border-slate-200 mt-4" />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesTabs;
