import React from "react";
import logo from "../../assets/logo2.png";

const Footer = () => {
  return (
    <footer className="bg-[#071C40] text-gray-700 px-6 py-12">
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Left Side - Social Media Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {[
            { name: "INSTAGRAM", icon: "fab fa-instagram", text: "Share visually appealing snippets of our latest web projects." },
            { name: "TWITTER", icon: "fab fa-twitter", text: "Tweet about interesting coding challenges you've overcome." },
            { name: "DRIBBBLE", icon: "fab fa-dribbble", text: "Showcase design elements of our web projects." },
            { name: "BEHANCE", icon: "fab fa-behance", text: "Create detailed presentations for our projects." },
          ].map((social, index) => (
            <div key={index} className="p-6 bg-blue-50 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  <i className={`${social.icon} text-lg text-gray-700`}></i>
                  <span className="text-gray-700 text-sm font-medium">{social.name}</span>
                </div>
                <i className="fas fa-arrow-up-right text-sm text-gray-700"></i>
              </div>
              <p className="text-gray-600 text-xs leading-relaxed">{social.text}</p>
            </div>
          ))}
        </div>

        {/* Right Side - Navigation, Newsletter, and Copyright */}
        <div className="space-y-4">
          {/* Navigation Links */}
          <div className="bg-blue-50 rounded-lg p-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="col-span-2">
                <img src={logo} alt="Logo" className="w-48 md:w-64" />
                <p className="text-gray-600 text-sm leading-relaxed mt-3">
                  Building digital experiences that inspire and innovate.
                </p>
              </div>
              <div>
                <h3 className="text-gray-700 mb-2">Links</h3>
                <ul className="space-y-2 text-sm">
                  {["About Us", "Contact", "Blogs"].map((link, index) => (
                    <li key={index}>
                      <a href="#" className="text-gray-600 hover:text-gray-900">
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Newsletter */}
          <div className="bg-blue-50 rounded-lg p-6">
            <p className="text-gray-600 text-xs mb-2">NEWSLETTER</p>
            <div className="flex flex-col sm:flex-row items-center justify-between gap-2">
              <span className="text-gray-700 text-sm">
                Subscribe to our newsletter
              </span>
              <div className="flex items-center border-b border-gray-400 py-1">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="bg-transparent text-gray-700 text-sm focus:outline-none placeholder-gray-500 px-2"
                />
                <i className="fas fa-arrow-up-right text-sm text-gray-700 ml-2"></i>
              </div>
            </div>
          </div>

          {/* Copyright */}
          <div className="bg-blue-50 rounded-lg p-6 text-center sm:text-left">
            <p className="text-gray-600 text-xs">
              © 2025 Zatca.co. All rights reserved.
            </p>
            <div className="flex justify-center sm:justify-end gap-4 text-xs mt-2">
              <a href="#" className="text-gray-600 hover:text-gray-900">
                Terms & Conditions
              </a>
              <a href="#" className="text-gray-600 hover:text-gray-900">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
