import React from 'react';

const NotFoundPage = () => {
  const handleHomeClick = () => {
    // In a real app, you'd use your router's navigation
    window.location.href = '/';
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="text-center space-y-6 max-w-2xl">
        {/* Error Code */}
        <h1 className="text-8xl font-bold text-gray-900">404</h1>
        
        {/* Main Message */}
        <h2 className="text-3xl font-semibold text-gray-800">
          Page not found
        </h2>
        
        {/* Description */}
        <p className="text-gray-600 text-lg max-w-md mx-auto">
          Sorry, we couldn't find the page you're looking for. Please check the URL or return to the homepage.
        </p>
        
        {/* Home Button */}
        <button
          onClick={handleHomeClick}
          className="inline-flex items-center px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors duration-200"
        >
          {/* Simple home icon using HTML entities */}
          <span className="mr-2" aria-hidden="true">⌂</span>
          Back to Homepage
        </button>
      </div>

      {/* Custom SVG illustration */}
      <div className="mt-12 text-gray-400">
        <svg
          className="w-48 h-48 mx-auto"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="0.5"
        >
          <circle cx="12" cy="12" r="10" />
          <circle cx="8" cy="9" r="1" fill="currentColor" />
          <circle cx="16" cy="9" r="1" fill="currentColor" />
          <path
            d="M8 15.5C10 13.5 14 13.5 16 15.5"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default NotFoundPage;