import React, { useState } from "react";
import logo from "../../assets/logo1.png";
import {
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton,
} from "@clerk/clerk-react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [language, setLanguage] = useState("en");

  const toggleLanguage = () => {
    const newLang = language === "en" ? "ar" : "en";
    setLanguage(newLang);
    const googleFrame = document.querySelector(".goog-te-combo");
    if (googleFrame) {
      googleFrame.value = newLang;
      googleFrame.dispatchEvent(new Event("change"));
    }
  };

  return (
    <section className="border-b border-gray-700 bg-[#071C40] fixed top-0 z-50 left-0 right-0">
      <div className="max-w-[80rem] mx-auto px-4 sm:px-4 lg:px-0">
        <div className="flex items-center justify-between py-3">
          <div className="flex items-center gap-4 lg:gap-6">
            <div className="pr-4 lg:pr-6 border-r-2 border-transparent lg:border-gray-700">
              <Link to="/">
                <img src={logo} alt="Zatca E-Invoicing Solutions" className="w-32 lg:w-48" />
              </Link>
            </div>
            <ul className="hidden lg:flex items-center gap-8 text-gray-200">
              <li className="hover:text-opacity-70 text-sm transition duration-200">
                <Link to="/about">About Us</Link>
              </li>
              <li className="hover:text-opacity-70 transition duration-200 text-sm">
                <Link to="/contact">Contact Us</Link>
              </li>
              <li className="hover:text-opacity-70 transition duration-200 text-sm">
                <Link to="/faqs">FAQs</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
            </ul>
          </div>
          <div className="flex items-center">
            <div className="hidden lg:flex flex-col items-start mr-4">
              <h1 className="text-slate-400 text-[10px]">Change Language</h1>
              <button className="text-slate-400 text-xs underline" onClick={toggleLanguage}>
                <span>{language === "en" ? "Arabic" : "English"}</span>
              </button>
            </div>
            <SignedIn>
              <div className="flex items-center space-x-3">
                <Link to="/dashboard" className="text-sm text-gray-200 bg-gradient-to-tl from-blue-700 to-blue-800 px-4 py-1 rounded-sm">
                  Dashboard
                </Link>
                <UserButton />
              </div>
            </SignedIn>
            <SignedOut>
              <button className="relative group inline-block py-2 px-4 text-sm text-blue-50 rounded-sm overflow-hidden transition duration-300 bg-gradient-to-tl from-blue-700 to-blue-800">
                <div className="absolute top-0 right-full w-full h-full bg-slate-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                <span className="relative">
                  <SignInButton mode="modal">Get Started Now</SignInButton>
                </span>
              </button>
            </SignedOut>
            <button
              className="ml-4 lg:hidden focus:outline-none"
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
              aria-label="Toggle mobile menu"
            >
              {mobileNavOpen ? (
                <svg className="w-8 h-8 text-blue-500" viewBox="0 0 24 24" fill="none">
                  <path d="M6 6L18 18M6 18L18 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              ) : (
                <svg className="w-8 h-8 text-blue-500" viewBox="0 0 24 24" fill="none">
                  <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              )}
            </button>
          </div>
        </div>
        {/* Mobile Menu */}
        {mobileNavOpen && (
          <div className="lg:hidden bg-[#071C40] text-white py-4 px-6">
            <ul className="space-y-4">
              <li>
                <Link to="/about" onClick={() => setMobileNavOpen(false)}>About Us</Link>
              </li>
              <li>
                <Link to="/contact" onClick={() => setMobileNavOpen(false)}>Contact Us</Link>
              </li>
              <li>
                <Link to="/faqs" onClick={() => setMobileNavOpen(false)}>FAQs</Link>
              </li>
              <li>
                <Link to="/pricing" onClick={() => setMobileNavOpen(false)}>Pricing</Link>
              </li>
              <li>
                <Link to="/blogs" onClick={() => setMobileNavOpen(false)}>Blogs</Link>
              </li>
            </ul>
            <div className="mt-8 lg:flex flex-col items-start mr-4">
              <h1 className="text-slate-400 text-[10px]">Change Language</h1>
              <button className="text-slate-400 text-xs underline" onClick={toggleLanguage}>
                <span>{language === "en" ? "Arabic" : "English"}</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Navbar;
