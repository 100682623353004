import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";


export default function PricingCard() {
  const navigate = useNavigate();

  const pricingPlans = [
    {
      id: "startup",
      title: "Gold Zatca",
      price: "$39",
      time: "/month",
      description: "Full Access to Simulation and Production ZATCA services that can be used seamlessly within 1 Month of time limit",
      features: ["All Simulation Service", "All Production Services", "Product support"],
      popular: false,
    },
    {
      id: "enterprise",
      title: "Platinum Zatca",
      price: "$200",
      time: "/year",
      description: "Full Access to Simulation and Production ZATCA services that can be used seamlessly within 1 Year of time limit",
      features: ["All Simulation Service", "All Production Services", "Product support"],
      popular: true,
    },
  ];

  const handleBuyNow = (plan) => {
    navigate("/checkout", {
      state: { selectedPlan: plan },
    });
  };

  return (
    <div className="container mx-auto px-4 py-12 max-w-7xl">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
        {pricingPlans.map((plan, index) => (
          <div
            key={index}
            className={`flex flex-col text-center rounded-lg p-6 md:p-8 border transition-all duration-300 ease-in-out 
              ${plan.popular
                ? "border-2 border-blue-500 bg-white hover:shadow-xl transform"
                : "border-slate-200 bg-white hover:shadow-lg"
              }`}
          >
            {plan.popular && (
              <div className="mb-4">
                <span className="inline-flex items-center px-3 py-1.5 rounded-full text-xs font-semibold bg-gradient-to-r from-blue-600 to-violet-600 text-white">
                  Most Popular
                </span>
              </div>
            )}

            <h4 className="text-lg sm:text-xl font-semibold text-gray-800 mb-4">
              {plan.title}
            </h4>

            <div className="mb-6">
              <span className="text-3xl sm:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600">
                {plan.price.includes("$") ? "$" : ""}
                {plan.price.replace("$", "")}
                <span className="text-xl">{plan.time}</span>
              </span>
              <p className="mt-2 text-xs sm:text-sm text-gray-500">
                {plan.description}
              </p>
            </div>

            <ul className="space-y-3 text-xs sm:text-sm flex-grow mb-6">
              {plan.features.map((feature, i) => (
                <li
                  key={i}
                  className="flex items-center justify-center gap-x-2"
                >
                  <svg
                    className="shrink-0 w-4 h-4 sm:w-5 sm:h-5 text-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span className="text-gray-700">{feature}</span>
                </li>
              ))}
            </ul>
            <SignedIn>
              <button
                onClick={() => handleBuyNow(plan)}
                className={`mt-auto py-2.5 sm:py-3 px-4 inline-flex justify-center items-center text-xs sm:text-sm font-semibold rounded-lg transition-all duration-300 
                ${plan.popular
                    ? "bg-gradient-to-r from-blue-600 to-violet-600 text-white hover:from-blue-700 hover:to-violet-700"
                    : "border border-gray-200 bg-white text-gray-800 hover:bg-gray-200"
                  }`}
              >
                Buy now
              </button>
            </SignedIn>

            <SignedOut>
              <Link to='/login'
                className={`mt-auto py-2.5 sm:py-3 px-4 inline-flex justify-center items-center text-xs sm:text-sm font-semibold rounded-lg transition-all duration-300 
                ${plan.popular
                    ? "bg-gradient-to-r from-blue-600 to-violet-600 text-white hover:from-blue-700 hover:to-violet-700"
                    : "border border-gray-200 bg-white text-gray-800 hover:bg-gray-200"
                  }`}
              >
                Buy now
              </Link>
            </SignedOut>

          </div>
        ))}
      </div>
    </div>
  );
}
