import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom'
import './App.css';
import Navbar from './components/Navbar.js/Navbar';
import Homepage from './pages/Home/Homepage';
import ClientLayout from './components/Layouts/Client/ClientLayout'
import UserLogin from './components/Auth/Login';
import ClientProtectedRoute from './pages/Client/ProtectedRoutes';
import PremiumProtectedRoute from './pages/Client/PremiumRoutes';
import Footer from './components/Footer/Footer'
import About from './pages/Home/About';
import Pricing from './pages/Home/Pricing/Pricing';
import ContactUs from './pages/Home/Contact';
import FAQs from './pages/Home/FAQs';
// ? Importing Simulation  Pages
import Checkout from './pages/Home/Pricing/CheckoutPage';
import Dashboard from './pages/Client/Dashboard'
import InvoiceHashing from './pages/Client/Simulation/InvoiceHashing/InvoiceHashing'
import InvoiceQR from './pages/Client/Simulation/InvoiceQR/InvoiceQR'
import TaxPayer from './pages/Client/Simulation/TaxPayer/TaxPayer'
import Onbaord from './pages/Client/Simulation/OnBoard/OnBoard'
import Compliance from './pages/Client/Simulation/Compliance/Compliance'
import Production from './pages/Client/Simulation/Production/Production'
import Clearance from './pages/Client/Simulation/Clearance/Clearance'
import Reporting from './pages/Client/Simulation/Reporting/Clearance'

// ? Importing Production Pages
import Auth from './pages/Client/Production/Auth/Authorization'
import InvoiceSigning from './pages/Client/Production/InvoiceHashing/Page'
import InvoiceQRGen from './pages/Client/Production/InvoiceQR/Page'
import TaxPayerCSR from './pages/Client/Production/TaxPayer/Page'
import OnboardDevice from './pages/Client/Production/Onboard/Page'
import ComplianceProd from './pages/Client/Production/Compliance/Page'
import ProductionProd from './pages/Client/Production/Production/Page'
import ClearanceProd from './pages/Client/Production/Clearance/Page'
import ReportingProd from './pages/Client/Production/Reporting/Page'
import ErrorPage from './ErrorPage'
import OrderStatusPage from './pages/Client/OrderStatusPage';
import Blogs from './pages/Home/Blogs/Blogs';
import InvoiceHashPage from './pages/Client/Production/InvoiceHashing/Page2'
import TaxPayerCNF from './pages/Client/Simulation/TaxPayer CNF/TaxPayer';
import InvoiceHash from './pages/Client/Simulation/InvoiceHas/InvoiceHashing';

import BlogDetails from './pages/Home/Blogs/BlogDetails';
function App() {
  const { pathname } = useLocation();

  // Scroll to top whenever the pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/login" element={<UserLogin />} />
        {/* <Route path="/blogs" element={<Blogs />} /> */}
        <Route path="/blogs" element={<BlogDetails />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="*" element={<ErrorPage />} />

        {/* Make all inner routes as Premium Protected */}
        <Route path='/dashboard' element={<ClientProtectedRoute Component={ClientLayout} />}>
          <Route index element={<Dashboard />} />
          <Route path='checkout-status' element={<OrderStatusPage />} />
          <Route path='invoice-signing' element={<PremiumProtectedRoute Component={InvoiceHashing} />} />
          <Route path='invoice-hashing' element={<PremiumProtectedRoute Component={InvoiceHash} />} />
          <Route path='invoice-qr' element={<PremiumProtectedRoute Component={InvoiceQR} />} />
          <Route path='taxpayer' element={<PremiumProtectedRoute Component={TaxPayer} />} />
          <Route path='taxpayer-cnf' element={<PremiumProtectedRoute Component={TaxPayerCNF} />} />
          <Route path='onboarding' element={<PremiumProtectedRoute Component={Onbaord} />} />
          <Route path='compliance' element={<PremiumProtectedRoute Component={Compliance} />} />
          <Route path='production-pcsid' element={<PremiumProtectedRoute Component={Production} />} />
          <Route path='clearance' element={<PremiumProtectedRoute Component={Clearance} />} />
          <Route path='reporting' element={<PremiumProtectedRoute Component={Reporting} />} />


          <Route path='authorization' element={<PremiumProtectedRoute Component={Auth} />} />
          <Route path='invoice-signing-prod' element={<PremiumProtectedRoute Component={InvoiceSigning} />} />
          <Route path='invoice-hash-prod' element={<PremiumProtectedRoute Component={InvoiceHashPage} />} />
          <Route path='invoice-qr-generator' element={<PremiumProtectedRoute Component={InvoiceQRGen} />} />
          <Route path='gen-taxpayer-csr' element={<PremiumProtectedRoute Component={TaxPayerCSR} />} />
          <Route path='onboard-device' element={<PremiumProtectedRoute Component={OnboardDevice} />} />
          <Route path='compliance-prod' element={<PremiumProtectedRoute Component={ComplianceProd} />} />
          <Route path='production-prod' element={<PremiumProtectedRoute Component={ProductionProd} />} />
          <Route path='clearance-prod' element={<PremiumProtectedRoute Component={ClearanceProd} />} />
          <Route path='reporting-prod' element={<PremiumProtectedRoute Component={ReportingProd} />} />
        </Route>

      </Routes>

      <Footer />
    </>
  );
}

export default App;
