import React from "react";
import fatoora from "../../assets/fatoora.png";
import sbc from "../../assets/sbc.svg";

function MoreOverSection() {
  return (
    <div className="max-w-[80rem] mx-auto px-4 flex flex-col md:flex-row items-center justify-center gap-6">
      <div className="w-full md:w-1/3 h-auto md:h-72 rounded px-6 bg-blue-50 border-slate-200 border flex flex-col items-center justify-center space-y-4 py-6">
        <img src={fatoora} alt="Fatoora" className="w-40 md:w-60" />
        <h1 className="mt-2 font-semibold text-lg md:text-xl text-center">
          E-Invoicing
        </h1>
        <p className="text-gray-600 text-center text-sm md:text-base">
          Electronic invoicing is a procedure that aims to convert the issuing
          of paper invoices and notes into an electronic process.
        </p>
      </div>

      <div className="w-full md:w-1/3 h-auto md:h-96 rounded bg-[#071C40] px-6 border-slate-200 border flex flex-col items-center justify-center space-y-4 py-6">
        <i className="fa-duotone fa-light fa-money-bill-1-wave text-3xl md:text-4xl text-gray-200"></i>
        <h1 className="mt-2 font-semibold text-lg md:text-xl text-center text-gray-200">
          Value Added Tax (VAT)
        </h1>
        <p className="text-gray-200 text-center text-sm md:text-base">
          Value Added Tax (or VAT) is an indirect tax imposed on all goods and
          services that are bought and sold by businesses, with a few
          exceptions. VAT is applied in more than 160 countries worldwide as a
          reliable source of revenue for state budgets.
        </p>
      </div>

      <div className="w-full md:w-1/3 h-auto md:h-72 rounded bg-blue-50 px-6 border-slate-200 border flex flex-col items-center justify-center space-y-4 py-6">
        <img src={sbc} alt="Saudi Business Center" className="w-40 md:w-60" />
        <h1 className="mt-2 font-semibold text-lg md:text-xl text-center">
          Saudi Business Center
        </h1>
        <p className="text-gray-600 text-center text-sm md:text-base">
          Your eGate to start and manage all your services with government
          agencies.
        </p>
      </div>
    </div>
  );
}

export default MoreOverSection;
