import React from "react";
import zatca1 from "../../assets/zatca1.png";
import zatca2 from "../../assets/zatca2.png";
import zatca3 from "../../assets/zatca3.png";
import zatca4 from "../../assets/zatca4.png";
import zatca5 from "../../assets/zatca5.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const FeatureItem = ({ children }) => (
  <li className="flex items-center space-x-2 text-sm md:text-base mb-2">
    <svg
      className="w-5 h-5 text-green-400 flex-shrink-0"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 13l4 4L19 7"
      />
    </svg>
    <span>{children}</span>
  </li>
);

const IconBubble = ({ icon, color }) => (
  <div className={`p-2 rounded-full ${color} shadow-lg`}>{icon}</div>
);
const timelineItems = [
  {
    number: "1",
    title: "ZATCA Approval",
    content:
      "Distinguished as among the first solutions to be approved by ZATCA, ZATCA API has been at the forefront of digital taxation in Saudi Arabia.",
    imageSrc: zatca1, // Replace with zatca1
  },
  {
    number: "2",
    title: "Rapid Adoption",
    content:
      "The platform's user base has expanded impressively from 100 to over 2000 digital e-invoices monthly for Saudi Business, illustrating the trust and reliance businesses place in our solutions.",
    imageSrc: zatca3, // Replace with zatca3
  },
  {
    number: "3",
    title: "Innovation and Flexibility",
    content:
      "ZATCA API stands out for its versatility in integration capabilities, offering ease of integration with any ERP/POS system through. Additionally, our standalone E-invoicing solution is specially designed to meet the unique needs of SMEs, further demonstrating our commitment to flexibility and innovation.",
    imageSrc: zatca4, // Replace with zatca4
  },
  {
    number: "4",
    title: "Comprehensive Solutions for ZATCA Compliance",
    content:
      "ZATCA API offers a range of essential services designed to ensure full compliance with ZATCA requirements. From digitally signing invoices and generating QR codes to seamlessly onboarding devices and managing compliance, our platform simplifies the process. With secure API integration and automated invoice clearance, we empower businesses to stay ahead of regulations while enhancing operational efficiency.",
    imageSrc: zatca5, // Replace with zatca5
  },
];
const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Top Saudi Arabia E-Invoicing Solution</title>
        <meta
          name="description"
          content="Saudi Arabia e-invoicing platform, ZATCA-approved & optimized for VAT compliance. Easily integrate your existing Enterprise Resource Planning (ERP) systems."
        />
        <meta
          name="keywords"
          content="ZATCA Services, ZATCA compliance, e-invoicing services, digital signing, hashing."
        />
        <meta property="og:title" content="About Us – ZATCA API" />
        <meta
          property="og:description"
          content="Learn how ZATCA API helps businesses achieve compliance with ZATCA regulations through reliable e-invoicing and hashing services."
        />
        <meta property="og:url" content="https://www.zatca.co/about" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="text-center pt-24 pb-12 bg-[#071C40]">
        <h1 className="text-4xl   text-gray-200 leading-tight">
          Pioneering
          <span className="text-blue-600 mx-1">E-Invoicing</span> and{" "}
          <span className="text-blue-600">ERP Services</span>
        </h1>
        <p className="mt-4 text-lg text-gray-300 max-w-2xl mx-auto">
          ZATCA leads the way in e-invoicing and ERP solutions, tailored for
          Saudi businesses. Our solutions blend simplicity with quality.
        </p>
      </div>
      <div className=" max-w-[80rem] mx-auto overflow-hidden">
        <div className="max-w-7xl w-full relative">
          <div className=" p-10 md:p-16">
            {/* Content Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
              {/* Left Image */}
              <div className="flex justify-center">
                <div className="relative rounded-lg shadow-lg overflow-hidden">
                  <img
                    src={zatca2}
                    alt="Top Saudi Arabia E-Invoicing Solution"
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute bottom-0 left-0 bg-blue-600 bg-opacity-60 text-white text-sm px-4 py-2">
                    Approved by Zakat, Tax and Customs Authority (ZATCA)
                  </div>
                </div>
              </div>

              {/* Right Content */}
              <div className="space-y-8">
                {/* Section 1 */}
                <div className="p-6 rounded border border-slate-200 shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    Innovative Solutions for Saudi Businesses
                  </h2>
                  <p className="text-gray-600 text-justify">
                    The ZATCA Services delivers tailored e-invoicing solutions
                    specifically for Saudi businesses. Our platform ensures
                    compliance with ZATCA regulations, enhancing operational
                    efficiency and minimizing risks associated with tax
                    documentation.
                  </p>
                </div>

                {/* Section 2 */}
                <div className="p-6 rounded border border-slate-200 hover:shadow-xl transition-shadow duration-300">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    A Collaborative Approach
                  </h2>
                  <p className="text-gray-600 text-justify">
                    The ZATCA Services collaborates in Saudi Arabia. This
                    strategic partnership combines local insights with global
                    expertise, enabling us to provide advanced e-invoicing
                    solutions tailored for the Saudi market.
                  </p>
                </div>

                {/* Section 3 */}
                <div className="p-6 rounded border border-slate-200 hover:shadow-xl transition-shadow duration-300">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    Where Simplicity Meets Excellence
                  </h2>
                  <p className="text-gray-600 text-justify">
                    At ZATCA Services, we prioritize simplicity without
                    compromising on quality, ensuring our solutions are
                    efficient, reliable, and suitable for businesses of all
                    sizes.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24 grid grid-cols-2 md:grid-cols-4 gap-8 relative z-10">
            {[
              {
                icon: "lightbulb",
                text: "Innovative",
                color: "from-yellow-400 to-orange-400",
              },
              {
                icon: "leaf",
                text: "Sustainable",
                color: "from-green-400 to-emerald-400",
              },
              {
                icon: "eye",
                text: "Visionary",
                color: "from-blue-400 to-indigo-400",
              },
              {
                icon: "clock",
                text: "Timeless",
                color: "from-purple-400 to-pink-400",
              },
            ].map(({ icon, text, color }, index) => (
              <div key={index} className="text-center group">
                <div
                  className={`w-20 h-20 mx-auto mb-4 rounded bg-gradient-to-br ${color} p-0.5 transform group-hover:scale-110 transition duration-300 shadow-md group-hover:shadow-lg`}
                >
                  <div className="w-full h-full bg-white rounded flex items-center justify-center">
                    <i
                      className={`fas fa-${icon} text-3xl bg-clip-text text-transparent bg-gradient-to-br ${color}`}
                    ></i>
                  </div>
                </div>
                <p className="text-lg font-bold text-gray-800 group-hover:text-gray-900 transition duration-300">
                  {text}
                </p>
              </div>
            ))}
          </div>
          {/* timeline */}
          <>
            <div className="container mx-auto w-full h-full mt-12 px-4 md:px-10">
              <div className="relative wrap overflow-hidden p-4 md:p-10 h-full">
                {/* Center line - hidden on mobile */}
                <div
                  className="border-2-2 absolute border-opacity-20 border-gray-700 h-full border hidden md:block"
                  style={{ left: "50%" }}
                />

                {timelineItems.map((item, index) => (
                  <div
                    key={index}
                    className={`mb-8 flex flex-col md:flex-row ${
                      index % 2 === 0
                        ? "md:justify-between items-start md:items-center w-full"
                        : "md:flex-row-reverse md:justify-between items-start md:items-center w-full"
                    }`}
                  >
                    {/* Timeline content for mobile (full width) and desktop (split) */}
                    <div className="order-2 md:order-1 w-full md:w-5/12 mt-4 md:mt-0" />

                    {/* Number indicator */}
                    <div className="z-20 flex items-center order-1 md:order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                      <h1 className="mx-auto font-semibold text-lg text-white">
                        {item.number}
                      </h1>
                    </div>

                    {/* Content card */}
                    <div className="order-3 md:order-1 rounded border border-slate-200 shadow-lg w-full md:w-5/12 px-4 md:px-6 py-4 mt-4 md:mt-0 bg-white">
                      <img
                        src={item.imageSrc}
                        alt={item.title}
                        className="w-full h-auto rounded"
                      />
                      <h3 className="mb-3 mt-2 font-bold text-gray-800 text-lg md:text-xl">
                        {item.title}
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
                        {item.content}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
          {/* Demo */}
          <>
            <div className="max-w-7xl my-12 mx-auto bg-gradient-to-r from-blue-600 to-indigo-800 rounded text-white p-8 md:p-12 overflow-hidden">
              <div className="flex flex-col md:flex-row items-center">
                <div className="md:w-1/2 mb-8 md:mb-0">
                  <h1 className="text-3xl md:text-4xl font-bold mb-6 leading-tight">
                    Achieve 100% ZATCA Compliance with ZATCA API's
                  </h1>
                  <ul className="mb-8">
                    <FeatureItem>Seamless integration</FeatureItem>
                    <FeatureItem>
                      E-invoice generation in milliseconds
                    </FeatureItem>
                    <FeatureItem>QR Code & Device Onboarding</FeatureItem>
                  </ul>
                  <button className="bg-white max-1/2 text-indigo-900 px-6 py-3 rounded-full font-semibold flex items-center space-x-2 hover:bg-indigo-100 transition-colors duration-300">
                    <Link to="/contact">Contact Us</Link>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                <div className="md:w-1/2 flex justify-center items-center">
                  <div className="relative">
                    <div className="bg-white text-black p-2 rounded-lg shadow-xl w-52 h-72 flex ">
                      <img src={zatca5} alt="Tax invoice" />
                    </div>
                    <div className="absolute -right-4 -bottom-4">
                      <IconBubble
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        }
                        color="bg-indigo-700"
                      />
                    </div>
                    <div className="absolute -left-1 -top-4 flex space-x-2">
                      <IconBubble
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                            <path
                              fillRule="evenodd"
                              d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                              clipRule="evenodd"
                            />
                          </svg>
                        }
                        color="bg-indigo-600"
                      />
                      <IconBubble
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                              clipRule="evenodd"
                            />
                          </svg>
                        }
                        color="bg-indigo-500"
                      />
                      <IconBubble
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z"
                              clipRule="evenodd"
                            />
                            <path
                              fillRule="evenodd"
                              d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z"
                              clipRule="evenodd"
                            />
                          </svg>
                        }
                        color="bg-indigo-400"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
